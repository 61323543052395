import { DatePicker, Divider, Form, Input, InputNumber, Switch } from 'antd';
import dayjs from 'dayjs';
import { WorkspaceEditChildModalProps } from './WorkspaceEditModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

const WorkspaceDetails: React.FC<WorkspaceEditChildModalProps> = (params) => {
  const form = Form.useFormInstance();
  const { isAdmin } = useSelector((state: RootState) => state.auth);

  return (
    <>
      <Form.Item
        name="name"
        label="Name"
        extra="Public visible name that describes this workspace"
        rules={[{ required: true, message: 'Please choose a workspace name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="expiryDate"
        label="Expiry date"
        extra="Workspace and data will be automatically deleted after expiry"
        hidden
      >
        <DatePicker
          allowClear
          maxDate={dayjs().add(6, 'months')}
          minDate={dayjs().add(1, 'days')}
        />
      </Form.Item>
      <Form.Item name="isPublic" valuePropName="checked" hidden>
        <Switch />
      </Form.Item>
    </>
  );
};

export default WorkspaceDetails;
